.gynecologist-aesthetic-gyneco,
.p {
  margin: 0;
}
.dr-karim-rehmat9,
.gynecologist-aesthetic-gyneco-container {
  position: absolute;
  left: 15px;
  line-height: 43px;
  font-weight: 500;
  display: inline-block;
}
.gynecologist-aesthetic-gyneco-container {
  top: 574px;
  color: var(--color-cadetblue-200);
  width: 411px;
  height: 58px;
}
.dr-karim-rehmat9 {
  top: 399px;
  width: 348px;
  height: 33px;
  z-index: 1;
}
.experience-practicing-at {
  margin: 0;
}
.consultation-with-dr,
.experience-practicing-at-container,
.you-can-book {
  position: absolute;
  top: 417px;
  left: 15px;
  line-height: 43px;
  font-weight: 500;
  display: inline-block;
  width: 348px;
  height: 33px;
  z-index: 2;
}
.consultation-with-dr,
.you-can-book {
  top: 435px;
  z-index: 3;
}
.consultation-with-dr {
  top: 455px;
  z-index: 4;
}
.doctor-profile-child,
.gynae-info-frame,
.gynae-info-frame1 {
  position: absolute;
  top: 33px;
  left: 23px;
  border-radius: var(--br-6xs);
  background-color: var(--color-whitesmoke-200);
  box-shadow: 0 1px 8.4px 1px rgba(181, 179, 179, 0.25);
  width: 34px;
  height: 33.1px;
}
.doctor-profile-child {
  display: none;
}
.gynae-info-frame1 {
  z-index: 2;
}
.appointment-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 18px 0;
}
.about {
  position: relative;
  line-height: 43px;
  font-weight: 600;
}
.appointment-frame-parent,
.text-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.text-container {
  flex-direction: row;
  padding: 0 var(--padding-mini);
}
.appointment-frame-parent {
  position: absolute;
  top: 33px;
  left: 0;
  width: 401px;
  flex-direction: column;
  gap: 14px 0;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-black);
  font-family: var(--font-poppins);
}
.image-6-icon {
  position: absolute;
  width: calc(100% - 12px);
  top: 761px;
  right: 5px;
  left: 7px;
  max-width: 100%;
  overflow: hidden;
  height: 78.9px;
  object-fit: cover;
}
.doctor-profile {
  width: 100%;
  height: 844px;
  position: relative;
  background-color: var(--color-whitesmoke-200);
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-3xs);
  color: var(--color-cadetblue-100);
  font-family: var(--font-poppins);
}
.doctor-profile-name {
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-cadetblue-200);
  font-size: 20px
}
.doctor-headings {
  font-weight: 600;
  font-family: var(--font-poppins);
  font-size: 12px
}
.custom-text-class {
  font-weight: 500;
  font-family: var(--font-poppins);
  color: var(--color-cadetblue-200);
  font-size: 10px;
  line-height: 22px;
}
.custom-ratings {
  font-weight: 500;
  font-family: var(--font-poppins);
  font-size: 10px;
  line-height: 43px;
}
