.range-slider {
    position: relative;
    width: 100%;
  }
  
  .form-range {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: linear-gradient(to right, #4CAF50 0%, #ddd 0%);
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s, background 0.2s;
  }
  
  .form-range:hover {
    opacity: 1;
  }
  
  .form-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top:-10px;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #4CAF50;
    cursor: pointer;
    border-radius: 10%;
  }
  
  .form-range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4CAF50;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .range-value {
    position: absolute;
    top: -30px;
    transform: translateX(-50%);
    padding: 5px;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    white-space: nowrap;
  }
  
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: transparent;
    border: none;
    border-radius: 3px;
  }
  
  .form-range::-moz-range-track {
    width: 100%;
    height: 8px;
    background: transparent;
    border: none;
    border-radius: 3px;
  }
  