.prescription-child,
.prescription-item {
  width: 345px;
  height: 148px;
  position: relative;
  border-radius: var(--br-9xs);
  /* background-color: var(--color-whitesmoke-200); */
  border: 2px solid var(--color-darkslateblue);
  box-sizing: border-box;
  display: none;
  z-index: 0;
}
.prescription-item {
  height: 151px;
  z-index: 1;
}
.image-24-icon {
  width: 40px;
  height: 33px;
  position: absolute;
  margin: 0 !important;
  right: 21px;
  bottom: 267px;
  object-fit: cover;
}

.prescription {
  display: flex;
  align-items: flex-start;
}

.prescription {
  width: 100%;
  position: relative;
  /* background-color: var(--color-whitesmoke-200); */
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-xl) 0 var(--padding-5xs);
  box-sizing: border-box;
  gap: 231px 0;
}



/* ============================== */

.arrow-inner {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-6xs);
  background-color: var(--color-whitesmoke-200);
  box-shadow: 0 1px 8.4px 1px rgba(181, 179, 179, 0.25);
  width: 34px;
  height: 33.1px;
  display: none;
}
.arrow-child1 {
  position: absolute;
  top: 17px;
  left: 13px;
  width: 2px;
  height: 14.7px;
  object-fit: contain;
  z-index: 1;
}
.arrow1 {
  height: 33px;
  width: 34px;
  position: relative;
  border-radius: var(--br-6xs);
  background-color: var(--color-whitesmoke-200);
  box-shadow: 0 1px 8.4px 1px rgba(181, 179, 179, 0.25);
}
.prescription1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}
.sub-frame-b1,
.sub-frame-d1 {
  display: flex;
  flex-direction: row;
}
.sub-frame-d1 {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.sub-frame-b1 {
  width: 268px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-lgi);
  box-sizing: border-box;
}
.image-16-icon {
  height: 76px;
  width: 385px;
  position: relative;
  object-fit: cover;
  display: none;
}
.diagnosis-frame-child {
  height: 25px;
  width: 92px;
  position: relative;
  background-color: var(--color-whitesmoke-200);
  display: none;
}
.now {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  z-index: 2;
}
.diagnosis-frame,
.diagnosis-frame-item {
  background-color: var(--color-whitesmoke-200);
}
.diagnosis-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-11xs) var(--padding-20xl) var(--padding-7xs)
    var(--padding-5xl);
  z-index: 1;
}
.diagnosis-frame-item {
  height: 25px;
  width: 92px;
  position: relative;
  display: none;
}
.previous {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  z-index: 2;
}
.diagnosis-frame1 {
  background-color: var(--color-whitesmoke-200);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-17xl) var(--padding-4xs) 0;
  z-index: 1;
  color: var(--color-black);
}
.complaint-cons-type-frames {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: var(--padding-10xl) var(--padding-37xl) var(--padding-2xl)
    var(--padding-45xl);
  background-image: url(/public/image-16@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
  font-size: var(--font-size-mini);
  color: var(--color-cadetblue-200);
  font-family: var(--font-poppins);
}
.a-f-b-myco-frames-child {
  height: 148px;
  width: 345px;
  position: relative;
  border-radius: var(--br-9xs);
  background-color: var(--color-whitesmoke-200);
  border: 2px solid var(--color-cadetblue-200);
  box-sizing: border-box;
  display: none;
}
.complaint1 {
  margin: 0;
}
.complaint {
  flex: 1;
  position: relative;
  line-height: 37px;
  z-index: 2;
}
.complaint3 {
  margin: 0;
}
.complaint2 {
  flex: 1;
  position: relative;
  line-height: 37px;
  z-index: 3;
  margin-left: -117px;
}
.days-note-frames {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.diagnosis1 {
  margin: 7px;
}
.diagnosisClass {
  margin: 7px;
  font-weight: 600;
}
.diagnosis {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 43px;
  display: inline-block;
  width: 49px;
  height: 30px;
  z-index: 6;
}
.diagnosis3 {
  margin: 0;
}
.diagnosis2 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 43px;
  display: inline-block;
  width: 49px;
  height: 30px;
  z-index: 7;
}
.afb-cs-drain-frames {
  width: 47px;
  height: 30px;
  position: relative;
  margin-top: -7px;
}
.consultation-type,
.consultation-type1 {
  flex: 1;
  position: relative;
  line-height: 39px;
  z-index: 4;
}
.consultation-type1 {
  z-index: 5;
  margin-left: -117px;
}
.days-note-frames1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: -7px;
}
.test,
.test1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 43px;
  display: inline-block;
  width: 21px;
  height: 37px;
  z-index: 2;
}
.test1 {
  z-index: 3;
}
.days-note-frames2 {
  width: 19px;
  height: 37px;
  position: relative;
  margin-top: -7px;
}
.med-name-tab-frames {
  width: 117px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 117px;
}
.fever,
.fever1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 43px;
  font-weight: 600;
  display: inline-block;
  width: 28px;
  height: 37px;
  z-index: 2;
}
.fever1 {
  z-index: 3;
}
.frame-complaint {
  align-self: stretch;
  height: 37px;
  position: relative;
}
.no8,
.no9 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 43px;
  font-weight: 600;
  display: inline-block;
  width: 14px;
  height: 37px;
  z-index: 4;
}
.no9 {
  z-index: 5;
}
.frame-complaint1 {
  width: 12px;
  height: 37px;
  position: relative;
  margin-top: -12px;
}
.frame-fever-yes {
  width: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.video-call1,
.video-call2 {
  flex: 1;
  position: relative;
  line-height: 37px;
  font-weight: 600;
  z-index: 6;
}
.video-call2 {
  z-index: 7;
  margin-left: -73px;
}
.frame-fever-no,
.video-call-frame2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.video-call-frame2 {
  align-self: stretch;
  flex-direction: row;
  margin-top: -8px;
}
.frame-fever-no {
  width: 73px;
  flex-direction: column;
}
.afb-cs-drain,
.blank-line3 {
  margin: 0;
}
.afb-cs-drain-container {
  height: 81px;
  position: relative;
  line-height: 43px;
  font-weight: 600;
  display: inline-block;
  z-index: 8;
}
.afb-cs-drain1,
.blank-line4 {
  margin: 0;
}
.afb-cs-drain-container1 {
  height: 81px;
  position: relative;
  line-height: 43px;
  font-weight: 600;
  display: inline-block;
  z-index: 9;
  margin-left: -182px;
}
.medicine-name-tab-panadol {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.tuberculosis,
.tuberculosis1 {
  position: relative;
  line-height: 43px;
  font-weight: 600;
  z-index: 2;
}
.tuberculosis1 {
  z-index: 3;
  margin-left: -68px;
}
.medicine-name-tab,
.morning-noon-frame,
.tuberculosis-frame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.morning-noon-frame {
  flex-direction: row;
  margin-top: -22px;
}
.medicine-name-tab,
.tuberculosis-frame {
  flex-direction: column;
}
.tuberculosis-frame {
  align-self: stretch;
  margin-top: -50px;
}
.medicine-name-tab {
  flex: 1;
  min-width: 118px;
}
.a-f-b-myco-frames,
.complaint-consultation-test-fr-child {
  border-radius: 10px;
  background-color: #c5edda;
  border: 2px solid #aba3ee;
  z-index: 1;
}
.a-f-b-myco-frames {
  /* align-self: stretch;
  display: flex;
  flex-direction: row; */
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: var(--padding-12xs) var(--padding-xs) var(--padding-9xs)
    var(--padding-2xs); */
  gap: 0 23px;
}
.complaint-consultation-test-fr-child {
  width: 345px;
  height: 151px;
  position: relative;
  box-sizing: border-box;
  display: none;
}
.medicine-name,
.medicine-name1 {
  flex: 1;
  position: relative;
  line-height: 43px;
  z-index: 2;
}
.medicine-name1 {
  z-index: 3;
  margin-left: -111px;
}
.fever-no-frame {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.tab-panadol,
.tab-panadol1 {
  flex: 1;
  position: relative;
  line-height: 43px;
  font-weight: 600;
  z-index: 6;
}
.tab-panadol1 {
  z-index: 7;
  margin-left: -111px;
}
.fever-no-frame1,
.frame-diagnosis-diagnosis {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.fever-no-frame1 {
  flex: 1;
}
.frame-diagnosis-diagnosis {
  width: 243px;
  gap: 0 21px;
}
.days,
.days1 {
  align-self: stretch;
  position: relative;
  line-height: 43px;
  z-index: 4;
}
.days1 {
  z-index: 5;
  margin-top: -43px;
}
.medicine-name-tab-frame {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.note,
.note1 {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 43px;
  font-weight: 600;
  display: inline-block;
  width: 32px;
  z-index: 2;
}
.note1 {
  z-index: 3;
}
.medicine-name-tab-frame1 {
  width: 32px;
  height: 43px;
  position: relative;
  color: var(--color-darkred);
}
.tuberculosis-frame1 {
  width: 111px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 27px 0;
}
.morning,
.morning1 {
  flex: 1;
  position: relative;
  line-height: 43px;
  z-index: 6;
}
.morning1 {
  z-index: 7;
  margin-left: -54px;
}
.med-name-tab-frame {
  width: 54px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.noon {
  margin-left: 50px;

}
.custom-margin {
  font-weight: 600;
  margin-left: 64px;

}
.noon1 {
  z-index: 3;
  margin-left: -54px;
}
.med-name-tab-frame1,
.tuberculosis-frame2 {
  width: 54px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.tuberculosis-frame2 {
  position: absolute;
  top: 28px;
  left: 0;
  width: 153px;
  justify-content: space-between;
}
.empty-frame3 {
  left: 0;
  line-height: 43px;
  font-weight: 600;
  display: inline-block;
  z-index: 4;
}
.empty-frame3,
.empty-frame4,
.evening-frame {
  position: absolute;
  top: 0;
  width: 20px;
}
.empty-frame4 {
  left: 0;
  line-height: 43px;
  font-weight: 600;
  display: inline-block;
  z-index: 5;
}
.evening-frame {
  left: 91px;
  height: 43px;
}
.a-f-b-mycobacterium-frame {
  height: 71px;
  flex: 1;
  position: relative;
}
.evening,
.evening1 {
  margin-left: 90px;
  align-self: stretch;
  position: relative;
  line-height: 43px;
  z-index: 2;
}
.evening1 {
  z-index: 3;
  margin-top: -43px;
}
.very-good-frame {
  width: 54px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.test-complaint-cons-type-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0 34px;
}
.medicine-name-tab1,
.medicine-name-tab2 {
  flex: 1;
  position: relative;
  line-height: 43px;
  font-weight: 600;
  white-space: pre-wrap;
  z-index: 8;
}
.medicine-name-tab2 {
  z-index: 9;
  margin-left: -212px;
}
.afb-c-s-drain-frame,
.diagnosis-frame2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.diagnosis-frame2 {
  flex: 1;
}
.afb-c-s-drain-frame {
  align-self: stretch;
  padding: 0 var(--padding-xs) 0 var(--padding-mid);
  margin-top: -22px;
}
.complaint-cons-type-frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.now-previous-frame {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 var(--padding-lg);
}
.very-good,
.very-good1 {
  flex: 1;
  position: relative;
  line-height: 43px;
  z-index: 2;
}
.very-good1 {
  z-index: 3;
  margin-left: -111px;
}
.empty-frame2,
.image-rectangle-frame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.image-rectangle-frame {
  width: 111px;
  flex-direction: row;
  margin-top: -4px;
}
.empty-frame2 {
  flex: 1;
  flex-direction: column;
  margin-left: -77px;
}
.frame-a-f-b-c-s-drain-mycobact,
.morning-evening-frame {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.frame-a-f-b-c-s-drain-mycobact {
  flex: 1;
}
.morning-evening-frame {
  align-self: stretch;
  padding: 0 var(--padding-sm);
  margin-top: -19px;
  text-align: left;
}
.complaint-consultation-test-fr {
  border-radius: 10px;
  /* background-color: #c5edda; */
  border: 2px solid #aba3ee;
  align-items: flex-start;
  padding: 0 var(--padding-6xl) 0 0;
  z-index: 1;
  /* text-align: center; */
  font-size: var(--font-size-2xs);
}
.complaint-consultation-test-fr,
.now-previous-frames,
.video-call-frame1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.video-call-frame1 {
  align-items: flex-end;
  /* padding: 0 var(--padding-lgi) 0 var(--padding-xl); */
  gap: 14px 0;
}
.now-previous-frames {
  align-items: center;
  gap: 20px 0;
  text-align: left;
  font-size: var(--font-size-3xs);
  color: var(--color-black);
}
.inner-frame1,
.sub-frame-a1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.sub-frame-a1 {
  flex: 1;
  flex-direction: column;
  gap: 29px 0;
}
.inner-frame1 {
  align-self: stretch;
  flex-direction: row;
  padding: 0;
  text-align: center;
  font-size: var(--font-size-lg);
  color: var(--color-cadetblue-100);
  font-family: var(--font-poppins);
}

