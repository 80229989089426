

.text-styling-one {
  font-weight: 600;
}

.text-red {
  color: #57928e;
}
.text-green {
  margin-top: -15px;
  font-weight: 600;
  color: rgba(164, 1, 1, 0.884);
}

.consultation-info {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap between elements */
}

.close-icon {
  cursor: pointer;
}

.complaint-info {
  margin-top: 5px; /* Adjust the margin between the date and complaint */
}

.doctor-info {
  margin-top: 5px; /* Adjust the margin between the complaint and doctor name */
}

.card {
  border: none;
  border-radius: 8px;
  /* background: linear-gradient(to right, #c5edda, #f3f7f6); */
  /* color: white; */
  /* padding: 20px; */
  /* margin-bottom: 20px; */
}

