body {
    overflow: hidden;
    font-family: serif;
}

.logo {
    box-shadow: 0px 4px 4px 0px #0000001F;
    background: #fff;
    height: 70px;
    width: 70%;
    border-radius: 0px 40px 40px 0px;
}

.main-div {
    /* height:120px; */
}

.header_bg {
    /* background: rgb(236, 49, 54);
    background: linear-gradient(135deg, rgba(236, 49, 54, 1) 0%, rgba(33, 36, 41, 1) 15%); */
    border-radius: 0 0 20px 20px;
    /* min-height: 100px; */
    background-color: #1bb367;
}