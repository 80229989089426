/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
} */

body {
  font-family: "Poppins", serif !important;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  /* Optional: Add background color */
  padding: 10px;
  /* Optional: Add padding */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  /* Optional: Add shadow for better visibility */
  z-index: 1000;
  /* Optional: Ensure it is above other elements */
}