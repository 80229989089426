.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

/* .loader-blur {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  z-index: -1;
} */

.spinner-overlay {
  position: fixed;
  /* Sticks to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  /* Black background with opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensures it stays on top of other content */
}

.spinner {
  width: 60px;
  height: 60px;
  border: 6px solid #fff;
  /* Black border */
  border-top-color: #198754;
  /* Red top border */
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}