.card {
  border: none;
  border-radius: 8px;
  /* background: linear-gradient(to right, #ecfafb, #69a6a1); */
  /* color: white; */
  /* padding: 20px; */
  /* margin-bottom: 20px; */
}

.card-title {
  font-weight: 500;
  font-family: var(--font-poppins);
  color: var(--color-cadetblue-200) !important;
  font-size: 20px;
  margin-bottom: 10px;
}
.modal-title {
  font-weight: 500;
  font-family: var(--font-poppins);
  color: var(--color-cadetblue-200) !important;
  font-size: 20px;
  margin-bottom: 10px;
}

.card-text p {
  font-size: var(--font-size-xs);
  color: var(--color-black);
  font-family: var(--font-poppins) !important;
  margin-bottom: 1px;
}

.card-text strong {
  font-weight: bold;
}

