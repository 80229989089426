.App {
  text-align: center;
}

.custom-heading {
  /* font-size: 14px !important;
  font-family: 'Kristen ITC', cursive; */
  /* Use Kristen ITC for h2 */
}

@font-face {
  font-family: "Kristen ITC";
  src: url("/public/insuranceIcons/ITCKRIST.TTF") format("truetype");
  /* Adjust the path and format as needed */
}

ul {
  list-style: none;
  padding-left: 0px !important;
  /* Adjust padding to properly align the text */
}

ul li {
  background: url("/public/insuranceIcons/listIcon.png") no-repeat left center;
  background-size: 10px 10px;
  /* Adjust size as needed */
  padding-left: 25px;
  /* Adjust padding to create space for the bullet image */
  margin-bottom: 10px;
  /* Adjust margin for spacing between items */
}

.fixed-bottom-center {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: white;
  /* Optional: to ensure the text is readable */
}

.fixed-bottom-center small {
  display: block;
  /* Ensures the text spans the full width */
  margin-bottom: 10px;
  /* Adds some spacing between the text and button */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body,
html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  /* background-color: rgb(243, 243, 243);  */
}

.bold-hr {
  border: 0;
  height: 3px;
  /* Adjust the height to make it bolder */
  background-color: transparent;
  /* Change the color as needed */
  margin-top: 20px;
  /* Optional: adjust margin for spacing */
  margin-bottom: 20px;
  /* Optional: adjust margin for spacing */
}

.selected_package {
  border: 1px solid #e0e0e0 !important;
  border-radius: 10px !important;
  overflow: hidden;
  padding: 0px !important;
}

.select_package_icon_bg {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  background-color: #e1efe9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coverage_bg {
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 14px 0px rgb(0, 0, 0, 0.1);
}

.swal2-confirm {
  background-color: #198754 !important;
}

.swal2-cancel {
  background-color: #dc3545 !important;
}

.terms_checkbox[type="checkbox"] {
  height: 17px;
  width: 17px;
  border: 1px solid #363636;
  border-radius: 0px !important;
}

.terms_checkbox[type="checkbox"]:focus {
  box-shadow: none;
}

.terms_checkbox:checked[type="checkbox"] {
  background-color: #1bb367;
}

.packages_list {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  border-left: 5px solid #1bb367;
  overflow: hidden;
}

.package_radio {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-color: #fff;
  outline: 2px solid #e0e0e0;
}

.package_radio_active {
  background-color: #1bb367;
}

.package_detail_list {
  padding-left: 2rem;
  list-style: none;
  margin: 0;
  position: relative;
}

.package_detail_list li {
  padding-right: 1rem !important;
  position: relative;
  margin-bottom: 1rem;
  counter-increment: list-counter;
}

.package_detail_list li:last-child {
  margin-bottom: 0;
}

.package_detail_list::before {
  content: "";
  counter-reset: list-counter;
}

.package_detail_list li::before {
  content: counter(list-counter);
  position: absolute;
  left: -1.5rem;
  top: 5px;
  width: 15px;
  height: 15px;
  background-color: #1bb367;
  color: white;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  font-size: 10px;
}

.package_detail_list li::after {
  content: "";
  position: absolute;
  left: -1.05rem;
  /* Adjust for proper positioning */
  top: 20px;
  /* Starts the vertical line below the marker */
  /* width: 0px;  */
  height: calc(100%);
  /* Makes the line stretch between markers */
  /* background: #E0E0E0; */
  border-radius: 50%;
  opacity: 1;
  z-index: 1;
  border-left: 1px dotted #e0e0e0;
  /* Dotted vertical line */
}

.package_detail_list li:last-child::after {
  display: none;
  /* Hide the line after the last item */
}

.pocketPackageCheck_checked {
  background-color: #1bb367;
}
ul.slider li {
  background: none;
  background-size: 10px 10px;
  padding-left: 0px;
  margin-bottom: 10px;
}
.slider-wrapper {
  padding-right: 10px !important;
}

.pocketPackages {
  /* height: 81px; */
  border: none !important;
  border-radius: 18px !important;
  box-shadow: 0px 0px 20px 0px rgb(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  min-height: 130px;
}

.pocketPackages:hover {
  border: 1px solid #1bb367 !important;
}

.pocketPackages_active {
  border: 1px solid #1bb367 !important;
}

.pocketPackageCheck {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pocketPackageCheck_checked {
  background-color: #1bb367;
}

/* Chat container */
.chat_container {
  width: 100%;
  max-width: 600px;
  /* margin: 0 auto;
  background: #f5f5f5;
  padding: 20px; */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Messages */
.chat_window {
  /* height: 400px; */
  height: calc(100vh - 123px);
  overflow-y: auto;
  padding-bottom: 10px;
  margin-bottom: 10px;
  overflow-x: hidden;
}

.messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  padding: 13px;
  border-radius: 20px;
  max-width: 85%;
}

.sender {
  align-self: flex-end;
  background-color: rgb(0, 0, 0, 0.3);
  color: #000;
}

.receiver {
  align-self: flex-start;
  background-color: rgb(128, 128, 128, 0.1);
  color: #333;
}

.message_sender {
  font-weight: bold;
}

.message_input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message_input input {
  padding: 10px;
  border-radius: 8px;
}

button {
  align-self: flex-end;
}

.chatOptions {
  border: none !important;
  background-color: rgb(128, 128, 128, 0.1) !important;
  color: #198754 !important;
  box-shadow: 0 0px 2px 0px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out;
}

.chatOptions:hover {
  background-color: rgb(25, 135, 84, 0.1) !important;
}
.message-content {
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-height 0.3s ease;
}

.message-content.expanded {
  -webkit-line-clamp: unset; /* Remove line clamp */
  overflow: visible; /* Allow full text to show */
}

.read-more-btn {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  display: inline-block;
  margin-top: 5px;
}

.read-more-btn:hover {
  text-decoration: underline;
}
