.frame-child34 {
  align-self: stretch;
  width: 401px;
  position: relative;
  background-color: #f5f5f5; /* var(--color-whitesmoke-200) */
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #dcdcdc; /* var(--color-gainsboro) */
  box-sizing: border-box;
  display: none;
}

.experience1,
.years {
  font-family: 'Poppins', sans-serif; /* var(--font-poppins) */
  position: relative;
  line-height: 43px;
  font-weight: 500;
  z-index: 1;
}

.years {
  line-height: 33px;
  font-weight: 500;
  z-index: 2;
}

.experience-box,
.parent-frame {
  display: flex;
  justify-content: flex-start;
}

.parent-frame {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 2px 0 0; /* var(--padding-10xs) */
  margin-top: -25px;
  font-size: 10px; /* var(--font-size-3xs) */
  color: #696969; /* var(--color-dimgray) */
}

.experience-box {
  flex-direction: column;
  align-items: center;
  padding: 0 8px 0 0; /* var(--padding-2xs) */
}

.about-label {
  width: 1px;
  height: 43px;
  position: relative;
  border-right: 1px solid #000000; /* var(--color-black) */
  box-sizing: border-box;
  z-index: 1;
}

.about-label-wrapper {
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 2px 0 0; /* var(--padding-8xs) var(--padding-10xs) */
  box-sizing: border-box;
}

.div,
.ratings {
  font-family: 'Poppins', sans-serif; /* var(--font-poppins) */
  position: relative;
  line-height: 43px;
  font-weight: 500;
  z-index: 1;
}

.div {
  position: absolute;
  margin: 0 !important;
  bottom: -8px;
  left: 10px;
  font-size: 10px; /* var(--font-size-3xs) */
  line-height: 33px;
  color: #696969; /* var(--color-dimgray) */
  z-index: 2;
}

.frame-wrapper7,
.ratings-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.ratings-parent {
  flex-direction: row;
  position: relative;
}

.frame-wrapper7 {
  flex-direction: column;
  padding: 0 16px 0 0; /* var(--padding-sm) */
}

.frame-child35 {
  width: 1px;
  height: 43px;
  position: relative;
  border-right: 1px solid #000000; /* var(--color-black) */
  box-sizing: border-box;
  z-index: 1;
}

.line-wrapper {
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0; /* var(--padding-8xs) */
  box-sizing: border-box;
}

.wait-time {
  font-family: 'Poppins', sans-serif; /* var(--font-poppins) */
  margin-bottom: -25px;
  position: relative;
  line-height: 43px;
  font-weight: 500;
  z-index: 1;
}

.wait-time-wrapper {
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
}

.mins {
  width: 48px;
  height: 33px;
  position: relative;
  font-size: 10px; /* var(--font-size-3xs) */
  line-height: 43px;
  font-weight: 500;
  color: #696969; /* var(--color-dimgray) */
  display: inline-block;
  flex-shrink: 0;
  z-index: 2;
}

.call-to-action-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 0 0 1px; /* var(--padding-12xs) */
}

.rectangle-parent16 {
  align-self: stretch;
  background-color: #f5f5f5; /* var(--color-whitesmoke-200) */
  box-shadow: 0 4px 4px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #dcdcdc; /* var(--color-gainsboro) */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 64px 1.5px 48px; /* var(--padding-4xl) var(--padding-11xs) var(--padding-3xl) */
  text-align: left;
  font-size: 12px; /* var(--font-size-xs) */
  color: #000000; /* var(--color-black) */
  font-family: 'Poppins', sans-serif; /* var(--font-poppins) */
}
